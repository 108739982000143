<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar color="primary" class="mt-10" icon size="50">
            <v-icon size="2rem" color="white">
              {{ icons.mdiBed }}
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">Record a Dream</h6>
        </v-card-text>
        <v-card-text> Let's see what was in your mind last night! </v-card-text>
        <v-card-text>
          <v-btn color="primary" class="mt-4" :to="{ name: 'record' }">
            Start
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar color="primary" class="mt-10" icon size="50">
            <v-icon size="2rem" color="white">
              {{ icons.mdiTextBoxSearchOutline }}
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">Analize a Text</h6>
        </v-card-text>
        <v-card-text>
          Let's see what we can tell about some text you provide
          us.</v-card-text
        >
        <v-card-text>
          <v-btn color="primary" class="mt-4" :to="{ name: 'record-text' }">
            Start
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar color="primary" class="mt-10" icon size="50">
            <v-icon size="2rem" color="white">
              {{ icons.mdiCamera }}
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">Talk about an Image</h6>
        </v-card-text>
        <v-card-text> Let's know what do you think of this images </v-card-text>
        <v-card-text>
          <v-btn color="primary" class="mt-4" disabled> Start </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiBed, mdiCamera, mdiTextBoxSearchOutline } from "@mdi/js"

export default {
  components: {},
  setup() {
    return {
      icons: {
        mdiBed,
        mdiCamera,
        mdiTextBoxSearchOutline,
      },
    }
  },
}
</script>
